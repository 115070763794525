import React from "react";
import SocialMediaBanner from '../components/SocialMediaBanner';
import WhatWeDo from '../components/WhatWeDo';
import TextBlock from '../components/TextBlock';
import Gallery from '../components/HomeGallery';
import SocialMediaLtrSlider  from '../components/SocialMediaLtrSlider';
import SocialMediaRtlSlider  from '../components/SocialMediaRtlSlider';
import SocialMediaLtrSlider2  from '../components/SocialMediaLtrSlider2';
import Envisioning  from '../components/Envisioning';


function App() {
  return (
    <>
      <div className="social_media_page">
        <SocialMediaBanner />
        <WhatWeDo />
        <Gallery />  
        <TextBlock Content="Meso is an Athens-based yacht charter company serving HNW clientele in Greece, Turkey, Spain, Albania, and more. We wanted to reference the Greek routes in the brand design while maintaining a simple look and feel to reflect the ease of booking with Meso." />  
        <SocialMediaLtrSlider />
        <SocialMediaRtlSlider />
        <SocialMediaLtrSlider2 />
        <Envisioning />
      </div>
    </>
  );
}

export default App;
