import React, { useState } from 'react';
import Item1 from '../assets/images/service-slide-1.jpg';
import Item2 from '../assets/images/gulet.jpeg';
import Item3 from '../assets/images/home-banner.jpg';
import Item4 from '../assets/images/social-media-banner.jpg';
import Item5 from '../assets/images/work-3.jpg';
import LeftArrow from '../assets/images/left-arrow.svg';
import RightArrow from '../assets/images/right-arrow.svg';
import TabArrow from '../assets/images/right_arrow_icon.svg';
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import 'swiper/css/free-mode';
import 'swiper/css/effect-fade';
import "swiper/css/pagination";


import { Navigation, EffectFade, Thumbs, FreeMode, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

function ServiceTabsPanel() {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    return (
        <>
            <div className="services_tabs_panel position-relative pb-120">
                <h6 className="text-uppercase letter-spacing-5 text-left">Services</h6>
                <div className="service_tabs_wrapper position-relative">
                    <div className='service_tab_items hideonmobile'>
                        <Swiper
                            onSwiper={setThumbsSwiper}
                            loop={true}
                            direction={'vertical'}
                            spaceBetween={35}
                            slidesPerView={5}
                            freeMode={true}
                            watchSlidesProgress={true}
                            modules={[FreeMode, Thumbs]}
                            className="mySwiper"
                        >
                            {/* Swiper Slides */}
                            <SwiperSlide>
                                <div className='service_tab_item'>
                                    <div className='service-name'>
                                        <h3>consulting</h3>
                                    </div>
                                    <div className='service_desc'>
                                        <span className='icon'><img src={TabArrow} alt="Service Image" /></span>
                                        <span className='text'>
                                            <strong>app-like website design</strong> for portfolio, e-commerce, hospitality websites, and more.
                                        </span>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='service_tab_item'>
                                    <div className='service-name'>
                                        <h3>web and app design</h3>
                                    </div>
                                    <div className='service_desc'>
                                        <span className='icon'><img src={TabArrow} alt="Service Image" /></span>
                                        <span className='text'>
                                            <strong>app-like website design</strong> for portfolio, e-commerce, hospitality websites, and more.
                                        </span>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='service_tab_item '>
                                    <div className='service-name'>
                                        <h3>branding</h3>
                                    </div>
                                    <div className='service_desc'>
                                        <span className='icon'><img src={TabArrow} alt="Service Image" /></span>
                                        <span className='text'>
                                            <strong>brand design and development</strong> from logo design to ground-up concept.
                                        </span>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='service_tab_item'>
                                    <div className='service-name'>
                                        <h3>development</h3>
                                    </div>
                                    <div className='service_desc'>
                                        <span className='icon'><img src={TabArrow} alt="Service Image" /></span>
                                        <span className='text'>
                                            <strong>app-like website design</strong> for portfolio, e-commerce, hospitality websites, and more.
                                        </span>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='service_tab_item'>
                                    <div className='service-name'>
                                        <h3>social media</h3>
                                    </div>
                                    <div className='service_desc'>
                                        <span className='icon'><img src={TabArrow} alt="Service Image" /></span>
                                        <span className='text'>
                                            <strong>app-like website design</strong> for portfolio, e-commerce, hospitality websites, and more.
                                        </span>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                    <div className='service_tab_images'>
                        <Swiper
                            loop={true}
                            slidesPerView={1.5}
                            spaceBetween={10}
                            effect={'slide'}
                            thumbs={thumbsSwiper ? { swiper: thumbsSwiper } : undefined}
                            modules={[FreeMode, EffectFade, Navigation, Pagination, Thumbs]}
                            centeredSlides={true}
                            pagination={{
                                type: 'progressbar',
                                el: '.swiper-pagination'
                            }}
                            navigation={{ nextEl: ".service-arrow-right", prevEl: ".service-arrow-left" }}
                            breakpoints={{
                                768: {
                                    effect: 'fade',
                                    crossFade: true,
                                    slidesPerView: 1,
                                    allowTouchMove: false
                                }
                            }}
                            className="mySwiper2"
                        >
                            {/* Swiper Slides */}
                            <SwiperSlide>
                                <img src={Item1} alt="Service Image" className='hideonmobile'/>
                                <div className='mobile_tab_content showonmobile' style={{ backgroundImage: `url(${Item1})` }}>
                                    <div className='wrapper'>
                                        <div className='tab_content_heading'>
                                            <h3>consulting</h3>
                                        </div>
                                        <div className='tab_content_wrapper'>
                                            <ul>
                                                <li>brand direction</li>
                                                <li>logo + icon design</li>
                                                <li>photography + videography  direction</li>
                                                <li>copywriting</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={Item2} alt="Service Image" className='hideonmobile'/>
                                <div className='mobile_tab_content showonmobile' style={{ backgroundImage: `url(${Item2})` }}>
                                    <div className='wrapper'>
                                        <div className='tab_content_heading'>
                                            <h3>web and app design</h3>
                                        </div>
                                        <div className='tab_content_wrapper'>
                                            <ul>
                                                <li>brand direction</li>
                                                <li>logo + icon design</li>
                                                <li>photography + videography  direction</li>
                                                <li>copywriting</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={Item3} alt="Service Image" className='hideonmobile'/>
                                <div className='mobile_tab_content showonmobile' style={{ backgroundImage: `url(${Item3})` }}>
                                    <div className='wrapper'>
                                        <div className='tab_content_heading'>
                                            <h3>branding</h3>
                                        </div>
                                        <div className='tab_content_wrapper'>
                                            <ul>
                                                <li>brand direction</li>
                                                <li>logo + icon design</li>
                                                <li>photography + videography  direction</li>
                                                <li>copywriting</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={Item4} alt="Service Image" className='hideonmobile'/>
                                <div className='mobile_tab_content showonmobile' style={{ backgroundImage: `url(${Item4})` }}>
                                    <div className='wrapper'>
                                        <div className='tab_content_heading'>
                                            <h3>development</h3>
                                        </div>
                                        <div className='tab_content_wrapper'>
                                            <ul>
                                                <li>brand direction</li>
                                                <li>logo + icon design</li>
                                                <li>photography + videography  direction</li>
                                                <li>copywriting</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide> 
                                <img src={Item5} alt="Service Image" className='hideonmobile'/>
                                <div className='mobile_tab_content showonmobile' style={{ backgroundImage: `url(${Item5})` }}>
                                    <div className='wrapper'>
                                        <div className='tab_content_heading'>
                                            <h3>social media</h3>
                                        </div>
                                        <div className='tab_content_wrapper'>
                                            <ul>
                                                <li>brand direction</li>
                                                <li>logo + icon design</li>
                                                <li>photography + videography  direction</li>
                                                <li>copywriting</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>

                    <div className="slider_nav d-flex align-items-center justify-content-center nowrap">
                        <button className="service-arrow-left arrow common_slider_arrow d-flex align-items-center justify-content-center">
                            <img src={LeftArrow} alt="Left Arrow" />
                        </button>
                        <div className="service-swiper-custom-scrollbar swiper-pagination"></div>
                        <button className="service-arrow-right arrow common_slider_arrow d-flex align-items-center justify-content-center">
                            <img src={RightArrow} alt="Right Arrow" />
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ServiceTabsPanel;
