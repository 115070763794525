import React from "react";
import { Link } from "react-router-dom";
import Background from '../assets/images/envisioning.jpg';
import BackgroundMobile from '../assets/images/envisioning-mobile.jpg';

import 'swiper/css';

function Envisioning(){
    return(
    <div className="envisioning position-relative">      
        <picture>
            <source media="(max-width: 645px)" srcSet={BackgroundMobile} />
            <img src={Background} alt="Logo" className="object-fit-cover" />
        </picture>          
        <div className="envisioning_content position-absolute z-2 top-0 start-0 h-100 w-100 d-flex flex-column align-items-center justify-content-center plr-100 text-center">
            <h4>let us know what you’re envisioning</h4>
            <Link to='/contact' className="text-white font-12 text-uppercase font-messina letter-spacing-5 underline_link">Contact Us</Link>
        </div>       
    </div>    
  );
}
export default Envisioning; 